import React from "react";
import Numeral from "numeral";
import moment from "moment";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import grey from "@material-ui/core/colors/grey";

export const currencyDisplay = (value) => Numeral(value).format("$0,0.00");

export const invoiceDateDisplay = (value) =>
  value ? moment(value.toDate()).format("MMMM DD, YYYY") : "-";

export const invoiceCurrencyDisplay = (value) =>
  `${Numeral(value).format("0,0.00")} AUD`;

export const colourfulInvoiceStatusDisplay = (value) => {
  switch (value) {
    case "Paid":
      return <strong style={{ color: green[700] }}>{value}</strong>;

    case "Unpaid":
      return <strong style={{ color: red[700] }}>{value}</strong>;

    case "Voided":
      return <span style={{ color: grey[700] }}>{value}</span>;

    default:
      return value;
  }
};
