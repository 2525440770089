import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import throttle from 'lodash/throttle';

const FirestoreAutocompleteField = (props) => {
    const { autocompleteRequest = null, autocompleteRequestHandler = null, getOptionLabel = null, renderOption = null, delay = 200, label, placeholder, onChange, required = false } = props;
    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);

    if(!autocompleteRequest) {
        console.error('autocompleteRequest is not defined on FirestoreAutocompleteField');
    }
    if(!autocompleteRequestHandler) {
        console.error('autocompleteRequestHandler is not defined on FirestoreAutocompleteField');
    }
    if(!getOptionLabel) {
        console.error('getOptionLabel is not defined on FirestoreAutocompleteField');
    }
    if(!renderOption) {
        console.error('renderOption is not defined on FirestoreAutocompleteField');
    }

    const fetch = React.useMemo(
        () =>
            throttle((request, callback) => {
                autocompleteRequest(request, callback)
            }, delay),
        [],
    );

    React.useEffect(() => {
        let active = true;

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch({ input: inputValue }, (results) => {
            if (active) {
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }

                // const processedResults = autocompleteRequestHandler(results);

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    return (
        <Autocomplete
            getOptionLabel={getOptionLabel}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            onChange={(event, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);

                if(onChange) {
                    onChange(event, newValue)
                }
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField {...params} label={label} placeholder={placeholder} variant="outlined" margin="normal" required={required} />
            )}
            renderOption={(option) => renderOption(option, inputValue)}
        />
    );
};

export default FirestoreAutocompleteField;