import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    background: "#F5F6F8",
    padding: theme.spacing(8)
  },
  center: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  body: {
    
  }
}));

const AppBody = ({ center = false, children }) => {
  const classes = useStyles();

  return (
    <div className={classnames(classes.root, center === true && classes.center)}>
      <div className={classes.body}>
        {children}
      </div>
    </div>
  );
};

export default AppBody;