import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  useFirestore,
  useFirestoreConnect,
  isLoaded
} from "react-redux-firebase";
import {
  currencyDisplay,
  colourfulInvoiceStatusDisplay
} from "../../utils/formatting";
import moment from "moment";
import { PageLayoutDefault } from "../../components/PageLayout";
import PageTitle from "../../components/PageTitle";
import FullLoading from "../../components/FullLoading";
import StickyHeadTable from "../../components/StickyHeadTable";
import Paper from "@material-ui/core/Paper";
import FloatingActionButtonZoom from "../../components/FloatingActionButtonZoom";
import AddIcon from "@material-ui/icons/Add";
import { getCollectionName } from "../../schema/invoice";

const columns = [
  { id: "number", label: "Invoice No.", minWidth: 100 },
  { id: "billingName", label: "Billing Name" },
  {
    id: "billingStatus",
    label: "Billing Status",
    minWidth: 100,
    format: (value) => colourfulInvoiceStatusDisplay(value)
  },
  {
    id: "billedOn",
    label: "Billed On",
    minWidth: 100,
    format: (value) =>
      value ? moment(value.toDate()).format("DD/MM/YYYY") : "-"
  },
  {
    id: "paidOn",
    label: "Paid On",
    minWidth: 100,
    format: (value) =>
      value ? moment(value.toDate()).format("DD/MM/YYYY") : "-"
  },
  {
    id: "total",
    label: "Total",
    minWidth: 100,
    format: (value) => currencyDisplay(value)
  }
];

const InvoiceListPage = (props) => {
  // Setup
  // -----------
  // Firestore
  const { uid } = useSelector((state) => state.firebase.auth);
  const COLLECTION_NAME = getCollectionName(uid);
  const firestore = useFirestore();
  useFirestoreConnect([{ collection: COLLECTION_NAME }]);
  const list = useSelector((state) => state.firestore.ordered[COLLECTION_NAME]);

  // Functions
  // -----------
  const handleDelete = (record) => {
    if (record) {
      firestore
        .collection(COLLECTION_NAME)
        .doc(record.id)
        .delete()
        .then(() => {
          firestore.get(COLLECTION_NAME);
        });
    }
  };

  if (!isLoaded(list)) {
    return <FullLoading />;
  }

  return (
    <PageLayoutDefault>
      <PageTitle>Invoices</PageTitle>
      <Paper elevation={1}>
        <StickyHeadTable
          columns={columns}
          rows={list}
          onDelete={handleDelete}
          deletable={true}
          editable={true}
          editLink={(item) => `/invoices/edit/${item.id}`}
          viewable={true}
          viewLink={(item) => `/invoices/view/${item.id}`}
        />
      </Paper>
      <FloatingActionButtonZoom
        label="Add"
        icon={<AddIcon />}
        component={Link}
        to="invoices/new"
        color="primary"
      />
    </PageLayoutDefault>
  );
};

export default InvoiceListPage;
