import React from "react";
import SidebarMenuItem from "../SidebarMenuItem";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 4),
    margin: 0
  }
}));

const SidebarMenu = ({ items = [] }) => {
  const classes = useStyles();

  return (
    <ul className={classes.root}>
      {items.map((item, key) => (
        <SidebarMenuItem key={key} item={item} />
      ))}
    </ul>
  );
};

export default SidebarMenu;
