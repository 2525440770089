import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { isLoaded, isEmpty } from "react-redux-firebase";
import DashboardPage from "../pages/DashboardPage";
import ClientListPage from "../pages/ClientListPage";
import ClientFormNewPage from "../pages/ClientFormNewPage";
import ClientFormEditPage from "../pages/ClientFormEditPage";
import InvoiceListPage from "../pages/InvoiceListPage";
import InvoiceFormNewPage from "../pages/InvoiceFormNewPage";
import InvoiceFormEditPage from "../pages/InvoiceFormEditPage";
import InvoiceViewPage from "../pages/InvoiceViewPage";
import NotFoundPage from "../pages/NotFoundPage";
import LoginPage from "../pages/LoginPage";
import FullLoading from "../components/FullLoading";

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated or if auth is not
// yet loaded
const PrivateRoute = ({ component: Component, children, ...rest }) => {
  const auth = useSelector((state) => state.firebase.auth);

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoaded(auth) && !isEmpty(auth) ? (
          Component ? (
            <Component {...props} />
          ) : (
            children
          )
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

const AppRoutes = (props) => {
  const auth = useSelector((state) => state.firebase.auth);

  if (!isLoaded(auth)) {
    return <FullLoading />;
  }

  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/dashboard" />
      </Route>
      <PrivateRoute path="/dashboard" component={DashboardPage} exact />
      <PrivateRoute path="/clients" component={ClientListPage} exact />
      <PrivateRoute path="/clients/new" component={ClientFormNewPage} exact />
      <PrivateRoute path="/clients/edit/:id" component={ClientFormEditPage} />
      <PrivateRoute path="/invoices" component={InvoiceListPage} exact />
      <PrivateRoute path="/invoices/new" component={InvoiceFormNewPage} exact />
      <PrivateRoute path="/invoices/edit/:id" component={InvoiceFormEditPage} />
      <PrivateRoute path="/invoices/view/:id" component={InvoiceViewPage} />
      <Route path="/login" component={LoginPage} exact />
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default AppRoutes;
