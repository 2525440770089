import React from "react";
import { firebase } from "../services/firebase";
import PeopleIcon from "@material-ui/icons/People";
import ReceiptIcon from "@material-ui/icons/Receipt";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

export const getMenuItems = ({ auth = {}, history }) => {
  const { uid = false } = auth;

  return [
    {
      title: "Clients",
      link: "/clients",
      icon: <PeopleIcon />
    },
    {
      title: "Invoices",
      link: "/invoices",
      icon: <ReceiptIcon />
    },
    {
      title: "Logout",
      onClick: (e) => {
        e.preventDefault();

        // Logout
        firebase.logout();

        // Redirect on success
        history.push("/login");
      },
      icon: <ExitToAppIcon />,
      hidden: !uid
    }
  ];
};
