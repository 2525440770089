import React from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { PageLayoutError } from "../../components/PageLayout";
import ErrorOnboarding from "../../components/ErrorOnboarding";

const NotFoundPage = (props) => {
  return (
    <PageLayoutError>
      <ErrorOnboarding 
        errorCode={'404'}
        errorHeading={'Page not found'}
      >
        <Typography variant="body1" component="p">
          The page that you are looking for is probably not in the mood of showing up.
        </Typography>
        <Button variant="contained" color="primary" component={Link} to="/">
          Go to Dashboard
        </Button>
      </ErrorOnboarding>
    </PageLayoutError>
  );
};

export default NotFoundPage;
