import React, { createRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";
import { Link } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import PrintIcon from "@material-ui/icons/Print";
import { PageLayoutDefault } from "../../components/PageLayout";
import PageTitle from "../../components/PageTitle";
import FullLoading from "../../components/FullLoading";
import { getCollectionName } from "../../schema/invoice";
import InvoiceViewTemplate from "../../features/InvoiceViewTemplate";
import ErrorPage from "../ErrorPage";

const REDIRECT_ON_SUCCESS = "/invoices";

const useStyles = makeStyles((theme) => ({
  buttonGroups: {
    margin: theme.spacing(2, 0, 4),
    textAlign: "left",

    "& > *": {
      marginRight: theme.spacing(1),

      "&:last-child": {
        marginRight: 0
      }
    }
  }
}));

const InvoiceViewPage = (props) => {
  const routerParams = props.match.params;
  const { id = "" } = routerParams;

  const classes = useStyles();

  // Setup
  // -----------
  // Auth
  const { uid } = useSelector((state) => state.firebase.auth);

  // Ref
  const documentRef = createRef();

  // Firestore
  const COLLECTION_NAME = getCollectionName(uid);
  useFirestoreConnect([{ collection: COLLECTION_NAME, doc: id }]);
  const fsdata = useSelector(
    ({ firestore: { data } }) =>
      data[COLLECTION_NAME] && data[COLLECTION_NAME][id]
  );

  if (id && !isLoaded(fsdata)) {
    return <FullLoading />;
  }

  if(!fsdata) {
    return <ErrorPage />
  }

  return (
    <PageLayoutDefault>
      <PageTitle parentTitle="Invoices" parentLink={REDIRECT_ON_SUCCESS}>
        View Invoice
      </PageTitle>
      <div className={classes.buttonGroups}>
        <Button
          variant="contained"
          component={Link}
          to={`/invoices/edit/${id}`}
          startIcon={<EditIcon />}
        >
          Edit
        </Button>
        <ReactToPrint
          trigger={() => (
            <Button
              variant="contained"
              color="primary"
              startIcon={<PrintIcon />}
            >
              Print
            </Button>
          )}
          content={() => documentRef.current}
        />
      </div>
      <InvoiceViewTemplate ref={documentRef} invoice={fsdata} />
    </PageLayoutDefault>
  );
};

export default InvoiceViewPage;
