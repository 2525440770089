import { applyMiddleware, compose, createStore, combineReducers } from "redux";
import thunk from "redux-thunk";
import { firebaseReducer, getFirestore } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore"; // <- needed if using firestore

// Middlewares
const middlewares = [thunk.withExtraArgument(getFirestore)];

// Add firebase to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer // <- needed if using firestore
});

// Create store with reducers and initial state
const initialState = {};
const store = createStore(
  rootReducer,
  initialState,
  compose(applyMiddleware(...middlewares))
);

export default store;
