import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DynamicFormFields from "../DynamicFormFields";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import { SCHEMA } from "../../schema/client";
import { buildFieldsFromSchema } from "../../utils/fields";

const useStyles = makeStyles((theme) => ({
  buttonHolder: {
    marginTop: theme.spacing(4),

    "& > *": {
      marginRight: theme.spacing(2)
    }
  }
}));

const ClientForm = (props) => {
  const { formData, setFormData, onSubmit } = props;

  // Setup
  // -----------
  // Styling
  const classes = useStyles();

  // Forms
  const { register, handleSubmit, errors } = useForm(); // initialize the hook

  const handleChange = (key, value) => {
    setFormData((prevState) => {
      return {
        ...prevState,
        [key]: value
      };
    });
  };

  const fieldsFromSchema = buildFieldsFromSchema(SCHEMA, formData, {
    register,
    errors,
    handleChange,
  });

  const fields = Object.values(fieldsFromSchema);

  // Functions
  // -----------
  /**
   * Helper function to create searchable index object
   * 
   * @param {string} text 
   */
  const createSearchableIndex = (text = '') => {
    const searchableIndex = {};
    const arr = text.toLowerCase().split('');

    let prevKey = '';

    for (const char of arr) {
      const key = prevKey + char;
      searchableIndex[key] = true;
      prevKey = key;
    }

    return searchableIndex;
  };

  const generateSearchableIndex = () => {
    const searchableIndex = createSearchableIndex(formData.name || '');
    handleChange('searchableIndex', searchableIndex);
  };

  // Hooks
  // -----------
  useEffect(generateSearchableIndex, [formData.name]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
        <Card>
          <CardHeader title="Details" />
          <Divider />
          <CardContent>
            <DynamicFormFields fields={fields} />
          </CardContent>
        </Card>
        <div className={classes.buttonHolder}>
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
          <Button type="button" component={Link} to="/clients">
            Cancel
          </Button>
        </div>
      </form>
    </>
  );
};

export default ClientForm;
