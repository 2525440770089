import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Zoom from "@material-ui/core/Zoom";
import Fab from "@material-ui/core/Fab";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "absolute",
    bottom: theme.spacing(8),
    right: theme.spacing(8)
  }
}));

export default function FloatingActionButtonZoom(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { label, icon, color, component, to } = props;

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen
  };

  return (
    <Zoom
      in
      timeout={transitionDuration}
      style={{
        transitionDelay: `${transitionDuration.exit}ms`
      }}
      unmountOnExit
    >
      <Fab
        aria-label={label}
        className={classes.fab}
        color={color}
        component={component}
        to={to}
      >
        {icon}
      </Fab>
    </Zoom>
  );
}
