import moment from "moment";

export const COLLECTION_NAME = "invoices";

export const getCollectionName = (uid) => `users/${uid}/${COLLECTION_NAME}`;

export const DEFAULT_FORM_DATA = {
  billedOn: new Date(),
  billingStatus: "Unpaid",
  dueDateOn: moment().add(4, "W").toDate(),
  paidOn: null,
  voidedOn: null,
  subtotal: 0,
  tax: 0,
  discount: 0,
  total: 0,
  items: {}
};
