import React from "react";
import { Redirect } from "react-router-dom";
import { PageLayoutDefault } from "../../components/PageLayout";

const DashboardPage = (props) => {
  return (
    <PageLayoutDefault>
      <Redirect to="/clients" />
    </PageLayoutDefault>
  );
};

export default DashboardPage;
