import React, { forwardRef } from "react";
import {
  invoiceCurrencyDisplay,
  invoiceDateDisplay
} from "../../utils/formatting";
import "./InvoiceViewTemplate.scss";

const InvoiceViewTemplate = forwardRef((props, ref) => {
  const { invoice = {} } = props;

  const {
    number,
    billedOn,
    dueDateOn,
    billingName,
    billingEmail,
    billingPhone,
    subtotal,
    tax,
    total,
    discount = 0,
    items
  } = invoice;

  const itemsData = Object.values(items).sort((a,b) => (a.sort > b.sort) ? 1 : ((b.sort > a.sort) ? -1 : 0));

  return (
    <div className="invoice-wrapper" ref={ref}>
      <div className="invoice">
        <div className="invoice__header">
          <div className="invoice__logo">
            <div className="logo">
              <div className="logo__main">
                Stephanus
                <br />
                Yanaputra
              </div>
              <div className="logo__sub">http://syanaputra.com</div>
            </div>
          </div>
        </div>
        <div className="invoice__body">
          <div className="invoice__info">
            <div className="invoice__number">Invoice #{number}</div>
            <div className="row">
              <div className="col text-right">
                <h3 className="heading heading--lv4 heading--condensed heading--uppercase heading--no-margin">
                  Date of Issue
                </h3>
                <div className="text-box">
                  <p>{invoiceDateDisplay(billedOn)}</p>
                </div>
              </div>
              <div className="col text-right">
                <h3 className="heading heading--lv4 heading--condensed heading--uppercase heading--no-margin">
                  Due Date
                </h3>
                <div className="text-box">
                  <p>{invoiceDateDisplay(dueDateOn)}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="invoice__detail">
            <div className="invoice__recipient">
              <span className="heading heading--lv4 heading--no-margin">
                {billingName}
              </span>
              <div className="text-box">
                <p>{billingEmail ? billingEmail : billingPhone}</p>
              </div>
            </div>

            <table className="invoice__table">
              <thead>
                <tr>
                  <th>Description</th>
                  <th className="invoice__col--amount text-center">Amount</th>
                  <th className="invoice__col--rate text-right">Rate</th>
                  <th className="invoice__col--total text-right">Total</th>
                </tr>
              </thead>
              <tbody>
                {itemsData.length > 0 ? (
                  itemsData.map((item, key) => {
                    const { name, description, quantity, quantifier } = item;

                    return (
                      <tr key={key}>
                        <td>
                          {name && <strong>{name}</strong>}
                          {name && description && <br />}
                          {description && <em>{description}</em>}
                        </td>
                        <th className="text-center">
                          {quantity} {quantifier}
                        </th>
                        <th className="text-right">
                          {invoiceCurrencyDisplay(item.individualPrice)}
                        </th>
                        <th className="text-right">
                          {invoiceCurrencyDisplay(item.total)}
                        </th>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="4">
                      <em>No items</em>
                    </td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr>
                  <th colSpan="3">Sub Total</th>
                  <th>{invoiceCurrencyDisplay(subtotal)}</th>
                </tr>
                {discount > 0 && (
                  <tr>
                    <th colSpan="3">Discount</th>
                    <th style={{color: "#980000"}}>{invoiceCurrencyDisplay(-discount)}</th>
                  </tr>
                )}
                <tr>
                  <th colSpan="3">Tax</th>
                  <th>{invoiceCurrencyDisplay(tax)}</th>
                </tr>
                <tr>
                  <th colSpan="3">Total</th>
                  <th>{invoiceCurrencyDisplay(total)}</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <div className="invoice__footer">
          <div className="row">
            <div className="col">
              <h3 className="heading heading--lv4">How to pay your invoice</h3>
              <div className="text-box text-box--small">
                <p>
                  Please transfer your payment to the following bank account:
                </p>

                <table>
                  <tr>
                    <th width="50%">BSB</th>
                    <td width="50%">063 019</td>
                  </tr>
                  <tr>
                    <th>ACCOUNT NUMBER</th>
                    <td>1038 9472</td>
                  </tr>
                  <tr>
                    <th>ACCOUNT NAME</th>
                    <td>Stephanus Yanaputra</td>
                  </tr>
                </table>

                <p>
                  With the invoice number as the description (E.g.: INV {number}
                  )
                </p>
              </div>
            </div>
            <div className="col">
              <h3 className="heading heading--lv4">Contact Details</h3>
              <div className="text-box text-box--small">
                <p>For any inquiries, please contact me via:</p>

                <table>
                  <tr>
                    <th width="35%">EMAIL</th>
                    <td width="65%">stephanus.yanaputra@gmail.com</td>
                  </tr>
                  <tr>
                    <th>PHONE</th>
                    <td>0430 097 395</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default InvoiceViewTemplate;
