import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import DynamicFormFields from "../DynamicFormFields";
import MiniSummary from "../../components/MiniSummary";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from "@material-ui/icons/Delete";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import { currencyDisplay } from "../../utils/formatting";

const BILLING_STATUS = [
  { label: "Unpaid", value: "Unpaid" },
  { label: "Paid", value: "Paid" },
  { label: "Voided", value: "Voided" }
];

const useStyles = makeStyles((theme) => ({
  buttonHolder: {
    marginTop: theme.spacing(3),
    "& > *": {
      margin: theme.spacing(1)
    }
  },
  itemHolder: {
    display: "flex",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    borderBottom: "1px solid #eee",
    "&:last-child": {
      borderBottom: 0
    }
  },
  itemHolderEach: {
    flex: 1,
    marginRight: theme.spacing(3),
  },
  itemDeleteHolder: {
    width: 65,
    marginTop: theme.spacing(2),
    textAlign: "right"
  },
  addItemButtonHolder: {
    marginBottom: theme.spacing(1),
    "&:last-child": {
      marginBottom: 0
    }
  },
}));

const InvoiceForm = (props) => {
  const { formData, setFormData, onSubmit } = props;

  // Setup
  // -----------
  // Styling
  const classes = useStyles();

  // Forms
  const [recalculateTotalWatcher, setRecalculateTotalWatcher] = useState(0);
  const { register, handleSubmit, errors } = useForm(); // initialize the hook
  const fields = {
    details: [
      {
        type: "text",
        name: "number",
        label: "Invoice No.",
        required: true,
        value: formData.number || "",
        inputRef: register({ required: true }),
        error: errors.number,
        disabled: false,
        onChange: (e) => handleChange("number", e.target.value)
      },
      {
        type: "textarea",
        name: "notes",
        label: "Notes",
        required: false,
        value: formData.notes || "",
        inputRef: register(),
        error: errors.notes,
        disabled: false,
        onChange: (e) => handleChange("notes", e.target.value)
      }
    ],
    billingStatus: [
      {
        type: "datepicker",
        name: "billedOn",
        label: "Billed On",
        required: true,
        value: formData.billedOn || "",
        inputRef: register({ required: true }),
        error: errors.billedOn,
        disabled: false,
        onChange: (date) => handleChange("billedOn", date.toDate())
      },
      {
        type: "radio",
        name: "billingStatus",
        label: "Biling Status",
        required: true,
        value: formData.billingStatus || false,
        items: BILLING_STATUS,
        error: errors.billingStatus,
        disabled: false,
        onChange: (e) => handleChange("billingStatus", e.target.value)
      },
      {
        visible:
          formData.billingStatus !== undefined &&
          formData.billingStatus === "Paid",
        type: "datepicker",
        name: "paidOn",
        label: "Paid On",
        required: true,
        value: formData.paidOn || "",
        inputRef: register(),
        error: errors.paidOn,
        disabled: false,
        onChange: (date) => handleChange("paidOn", date.toDate())
      },
      {
        visible:
          formData.billingStatus !== undefined &&
          formData.billingStatus === "Voided",
        type: "datepicker",
        name: "voidedOn",
        label: "Voided On",
        required: true,
        value: formData.voidedOn || "",
        inputRef: register(),
        error: errors.voidedOn,
        disabled: false,
        onChange: (date) => handleChange("voidedOn", date.toDate())
      }
    ],
    billingInformation: [
      {
        type: "autocomplete-client",
        name: "autocompleteClient",
        label: "Find Client",
        placeholder: "Search for client name",
        required: false,
        disabled: false,
        onChange: (e, client) => {
          if(client) {
            handleChange("billingName", client?.name || '');
            handleChange("billingEmail", client?.email || '');
            handleChange("billingPhone", client?.phone || '');
          }
        },
      },
      {
        type: "text",
        name: "billingName",
        label: "Name",
        required: true,
        value: formData.billingName || "",
        inputRef: register(),
        error: errors.billingName,
        disabled: false,
        onChange: (e) => handleChange("billingName", e.target.value)
      },
      {
        type: "email",
        name: "billingEmail",
        label: "Email",
        required: false,
        value: formData.billingEmail || "",
        inputRef: register(),
        error: errors.billingEmail,
        disabled: false,
        onChange: (e) => handleChange("billingEmail", e.target.value)
      },
      {
        type: "text",
        name: "billingPhone",
        label: "Phone",
        required: false,
        value: formData.billingPhone || "",
        inputRef: register(),
        error: errors.billingPhone,
        disabled: false,
        onChange: (e) => handleChange("billingPhone", e.target.value)
      }
    ],
    overview: [
      {
        type: "currency",
        name: `subtotal`,
        label: "Subtotal",
        required: true,
        value: formData.subtotal || "",
        inputRef: register(),
        error: errors.subtotal,
        disabled: true
      },
      {
        type: "currency",
        name: `total`,
        label: "Total",
        required: true,
        value: formData.total || "",
        inputRef: register(),
        error: errors.total,
        disabled: true
      }
    ]
  };

  // Functions
  // -----------
  const handleChange = (key, value) => {
    setFormData((prevState) => {
      console.log("DEBUG setFormData", {
        ...prevState,
        [key]: value
      });

      return {
        ...prevState,
        [key]: value
      };
    });
  };

  const setItemsFormData = (newData) => {
    setFormData((prevState) => {
      return {
        ...formData,
        items: newData
      };
    });
  };

  const handleChangeForItems = (id, key, value) => {
    setFormData((prevState) => {
      const itemData = {
        ...formData.items[id],
        [key]: value
      };

      const TRIGGERING_KEYS = [
        "quantity",
        "individualPrice",
        "discount",
        "taxable",
        "taxRate",
        "taxInclusive"
      ];

      if (TRIGGERING_KEYS.includes(key)) {
        // recalculate total
        const {
          quantity = 0,
          individualPrice = 0,
          discount = 0,
          taxRate = 0,
          taxable = false,
          taxInclusive = true
        } = itemData || {};

        const finalQuantity = Math.max(quantity, 0);
        const finalIndividualPrice = Math.max(individualPrice, 0);
        const finalDiscount = Math.max(discount, 0);
        const finalTaxRate = Math.max(taxRate, 0);

        itemData.subtotal = finalQuantity * finalIndividualPrice;
        itemData.discount = finalDiscount;

        const totalBeforeTax = itemData.subtotal - itemData.discount;
        itemData.total = totalBeforeTax;

        if (taxable === true) {
          itemData.tax = (totalBeforeTax * finalTaxRate) / 100;
        } else {
          itemData.tax = 0;
        }

        if (!taxInclusive) {
          itemData.total += itemData.tax;
        }

        // Flush
        setRecalculateTotalWatcher((prevState) => prevState + 1);
      }

      return {
        ...prevState,
        items: {
          ...prevState.items,
          [id]: {
            ...prevState.items[id],
            ...itemData
          }
        }
      };
    });
  };

  const createNewItem = (e) => {
    e.preventDefault();

    const randomKey = `item-${new Date().getTime()}`;
    setItemsFormData({
      ...formData.items,
      [randomKey]: {
        quantity: 1,
        quantifier: "",
        individualPrice: 0.0,
        discount: 0.0,
        taxable: false,
        taxInclusive: true,
        taxRate: 0.0,
        tax: 0,
        subtotal: 0.0,
        total: 0.0,
        sort: Object.values(formData.items).length,
      }
    });
  };

  const removeItem = (pos) => {
    const newItemsForData = { ...formData.items };
    delete newItemsForData[pos];

    setItemsFormData(newItemsForData);
    setRecalculateTotalWatcher((prevState) => prevState + 1);
  };

  const recalculateInvoice = () => {
    let subtotal = 0;
    let discount = 0;
    let tax = 0;
    let total = 0;

    Object.values(formData.items).forEach((item) => {
      subtotal += item.subtotal;
      discount += item.discount;
      tax += item.tax;
      total += item.total;
    });

    handleChange("subtotal", subtotal || 0);
    handleChange("discount", discount || 0);
    handleChange("tax", tax || 0);
    handleChange("total", total || 0);
  };

  // Hooks
  // -----------
  useEffect(recalculateInvoice, [recalculateTotalWatcher]);

  const itemsData = Object.entries(formData.items).sort((a,b) => (a.sort > b.sort) ? 1 : ((b.sort > a.sort) ? -1 : 0));

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate={true}>
        <Grid container spacing={3} alignItems="stretch">
          <Grid item xs={12} sm={4}>
            <Card>
              <CardHeader title="Details" />
              <Divider />
              <CardContent>
                <DynamicFormFields fields={fields.details} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card>
              <CardHeader title="Billing Information" />
              <Divider />
              <CardContent>
                <DynamicFormFields fields={fields.billingInformation} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card>
              <CardHeader title="Billing Status" />
              <Divider />
              <CardContent>
                <DynamicFormFields fields={fields.billingStatus} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={3} alignItems="stretch">
          <Grid item xs={12} sm={8}>
            <Card>
              <CardHeader title="Items" />
              <Divider />
              <CardContent>
                <div className={classes.addItemButtonHolder}>
                  <Button
                    type="button"
                    variant="contained"
                    onClick={createNewItem}
                    startIcon={<AddCircleOutlineIcon />}
                  >
                    Add Item
                  </Button>
                </div>
                {itemsData.map(([itemKey, itemData]) => {
                  const itemFields = {
                    basic: [
                      {
                        type: "text",
                        name: `name-${itemKey}`,
                        label: "Item Name",
                        required: true,
                        value: itemData.name || "",
                        inputRef: register({ required: true }),
                        error: errors[`name-${itemKey}`],
                        disabled: false,
                        onChange: (e) =>
                          handleChangeForItems(itemKey, "name", e.target.value)
                      },
                      {
                        type: "textarea",
                        name: `description-${itemKey}`,
                        label: "Item Description",
                        required: false,
                        value: itemData.description || "",
                        inputRef: register(),
                        error: errors[`description-${itemKey}`],
                        disabled: false,
                        onChange: (e) =>
                          handleChangeForItems(
                            itemKey,
                            "description",
                            e.target.value
                          )
                      }
                    ],
                    quantity: [
                      {
                        type: "number",
                        name: `quantity-${itemKey}`,
                        label: "Quantity",
                        required: true,
                        value: itemData.quantity,
                        inputRef: register(),
                        error: errors[`quantity-${itemKey}`],
                        disabled: false,
                        onChange: (e) =>
                          handleChangeForItems(
                            itemKey,
                            "quantity",
                            Number(e.target.value)
                          )
                      },
                      {
                        type: "text",
                        name: `quantifier-${itemKey}`,
                        label: "Quantifier",
                        required: false,
                        value: itemData.quantifier || "",
                        inputRef: register(),
                        error: errors[`quantifier-${itemKey}`],
                        disabled: false,
                        onChange: (e) =>
                          handleChangeForItems(
                            itemKey,
                            "quantifier",
                            e.target.value
                          )
                      }
                    ],
                    price: [
                      {
                        type: "currency",
                        name: `individualPrice-${itemKey}`,
                        label: "Individual Price",
                        required: true,
                        value: itemData.individualPrice,
                        placeholder: "0.00",
                        inputRef: register(),
                        error: errors[`individualPrice-${itemKey}`],
                        disabled: false,
                        onChange: (e) =>
                          handleChangeForItems(
                            itemKey,
                            "individualPrice",
                            Number(e.target.value)
                          )
                      },
                      {
                        type: "currency",
                        name: `discount-${itemKey}`,
                        label: "Discount",
                        required: true,
                        value: itemData.discount,
                        placeholder: "0.00",
                        inputRef: register(),
                        error: errors[`discount-${itemKey}`],
                        disabled: false,
                        onChange: (e) =>
                          handleChangeForItems(
                            itemKey,
                            "discount",
                            Number(e.target.value)
                          )
                      }
                    ],
                    tax: [
                      {
                        type: "switch",
                        name: `taxable-${itemKey}`,
                        label: "Taxable",
                        required: true,
                        checked: itemData.taxable,
                        inputRef: register(),
                        error: errors[`taxable-${itemKey}`],
                        disabled: false,
                        onChange: (e) =>
                          handleChangeForItems(
                            itemKey,
                            "taxable",
                            e.target.checked
                          )
                      },
                      {
                        visible:
                          itemData.taxable !== undefined && itemData.taxable,
                        type: "switch",
                        name: `taxInclusive-${itemKey}`,
                        label: "Tax Inclusive",
                        required: true,
                        checked: itemData.taxInclusive,
                        inputRef: register(),
                        error: errors[`taxInclusive-${itemKey}`],
                        disabled: false,
                        onChange: (e) =>
                          handleChangeForItems(
                            itemKey,
                            "taxInclusive",
                            e.target.checked
                          )
                      },
                      {
                        visible:
                          itemData.taxable !== undefined && itemData.taxable,
                        type: "percentage",
                        name: `taxRate-${itemKey}`,
                        label: "TaxRate",
                        required: false,
                        value: itemData.taxRate,
                        placeholder: "0.00",
                        inputRef: register(),
                        error: errors[`taxRate-${itemKey}`],
                        disabled: false,
                        onChange: (e) =>
                          handleChangeForItems(
                            itemKey,
                            "taxRate",
                            Number(e.target.value)
                          )
                      }
                    ]
                  };

                  return (
                    <div className={classes.itemHolder}>
                      <div className={classes.itemHolderEach}>
                        <DynamicFormFields fields={itemFields.basic} />
                      </div>
                      <div className={classes.itemHolderEach}>
                        <DynamicFormFields fields={itemFields.quantity} />
                      </div>
                      <div className={classes.itemHolderEach}>
                        <DynamicFormFields fields={itemFields.price} />
                      </div>
                      <div className={classes.itemHolderEach}>
                        <DynamicFormFields fields={itemFields.tax} />
                      </div>
                      <div className={classes.itemDeleteHolder}>
                        <Button
                          type="button"
                          onClick={(e) => removeItem(itemKey)}
                        >
                          <DeleteIcon />
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card>
              <CardHeader title="Overview" />
              <Divider />
              <CardContent>
                <MiniSummary
                  items={[
                    {
                      label: "Subtotal",
                      value: currencyDisplay(formData.subtotal)
                    },
                    {
                      label: "Discount",
                      value: currencyDisplay(-formData.discount),
                      isNegative: formData.discount > 0
                    },
                    {
                      label: "Tax",
                      value: currencyDisplay(formData.tax)
                    },
                    {
                      label: "Total",
                      value: currencyDisplay(formData.total),
                      highlight: true
                    }
                  ]}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <div className={classes.buttonHolder}>
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
          <Button type="button" component={Link} to="/invoices">
            Cancel
          </Button>
        </div>
      </form>
    </>
  );
};

export default InvoiceForm;
