import React from "react";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  menuItemWrapper: {
    flex: 1,
    background: "#F5F6F8",
    padding: 0,
    margin: 0,
    listStyle: "none",
    marginBottom: theme.spacing(2),
    "&:last-child": {
      marginBottom: 0
    }
  },
  menuLink: {
    display: "inline-block",
    verticalAlign: "middle",
    color: "#333",
    fontSize: "12px",
    fontWeight: "bold",
    textDecoration: "none",
    textTransform: "uppercase",
    opacity: 0.6,
    transition: "all 0.2s",
    cursor: "pointer",
    "&:hover, &:focus": {
      opacity: 1
    },
    "&.active": {
      opacity: 1,
      color: theme.palette.primary.main
    }
  },
  menuIcon: {
    display: "inline-block",
    verticalAlign: "middle",
    marginRight: theme.spacing(2)
  },
  menuText: {
    display: "inline-block",
    verticalAlign: "middle"
  }
}));

const SidebarMenuItem = ({ item }) => {
  const { title, link = null, icon = "", onClick = null, hidden = false } =
    item || {};
  const classes = useStyles();

  if (hidden) return "";

  return (
    <li className={classes.menuItemWrapper}>
      {link ? (
        <NavLink to={link} className={classes.menuLink}>
          {icon && <span className={classes.menuIcon}>{icon}</span>}
          {title && <span className={classes.menuText}>{title}</span>}
        </NavLink>
      ) : (
        <span className={classes.menuLink} onClick={onClick}>
          {icon && <span className={classes.menuIcon}>{icon}</span>}
          {title && <span className={classes.menuText}>{title}</span>}
        </span>
      )}
    </li>
  );
};

export default SidebarMenuItem;
