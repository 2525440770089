import React from 'react';
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FirestoreAutocompleteField from '../../components/FirestoreAutocompleteField';
import { getCollectionName } from "../../schema/client";

const ClientAutocompleteField = (props) => {
    const { onChange } = props;
    
    // Setup
    // -----------
    // Firestore
    const { uid } = useSelector((state) => state.firebase.auth);
    const COLLECTION_NAME = getCollectionName(uid);
    const firestore = useFirestore();

    // Functions
    const request = (params, callback) => {
        const keyword = params.input || '';
        const docRef = firestore.collection(COLLECTION_NAME).orderBy(`searchableIndex.${keyword}`).limit(5);

        docRef.get().then((querySnapshot) => {        
            let list = [];

            querySnapshot.forEach(function(doc) {
                list.push(doc.data());
            });

            callback(list);
        });
    };

    const handleResponse = (results) => {
        console.log(results);
    };

    return (
        <FirestoreAutocompleteField
            {...props}
            autocompleteRequest={request}
            autocompleteRequestHandler={handleResponse}
            onChange={onChange}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
            renderOption={(option, inputText) => {
                return (
                    <Grid container alignItems="center">
                        <Grid item xs>
                            <Typography variant="body1">
                                {option.name}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {option.email}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                <strong>Notes:</strong> {option.notes || '-'}
                            </Typography>
                        </Grid>
                    </Grid>
                );
            }}
        />
    );
};

export default ClientAutocompleteField;