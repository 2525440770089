import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  logoContainer: {},
  logoText: {
    display: "inline-block",
    fontSize: "28px",
    fontWeight: 700,
    textTransform: "uppercase"
  }
}));

const Logo = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.logoContainer}>
      <div className={classes.logoText}>Invoicer</div>
    </div>
  );
};

export default Logo;
