import React from "react";
import { useSelector } from "react-redux";
import { isLoaded, isEmpty } from "react-redux-firebase";
import { useHistory } from "react-router-dom";
import GoogleButton from "react-google-button";
import { firebase } from "../../services/firebase";
import FullLoading from "../../components/FullLoading";

const LoginPage = (props) => {
  const auth = useSelector((state) => state.firebase.auth);
  let history = useHistory();

  /**
   * onAfterLogin function
   */
  const onAfterLogin = () => {
    const redirectTo = props?.location?.state?.from?.pathname || "/dashboard";

    if (redirectTo) {
      history.push(redirectTo);
    }
  };

  /**
   * onGoogleLoginClick function
   */
  const onGoogleLoginClick = async () => {
    await firebase.login({ provider: "google", type: "popup" });
    onAfterLogin();
  };

  if (!isLoaded(auth)) {
    return <FullLoading />;
  }

  return (
    <>
      {isEmpty(auth) ? (
        <GoogleButton onClick={onGoogleLoginClick} />
      ) : (
        <pre>{JSON.stringify(auth, null, 2)}</pre>
      )}
    </>
  );
};

export default LoginPage;
