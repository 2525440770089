import React from "react";
import AppBody from "../../App/AppBody";
import AppWrapper from "../../App/AppWrapper";
import AppSidebar from "../../App/AppSidebar";
import "./PageLayoutError.scss";

export default function PageLayoutError({ showSidebar = true, children }) {
  return (
    <AppWrapper>
      {showSidebar && <AppSidebar />}
      <AppBody center={true}>
        {children}
      </AppBody>
    </AppWrapper>
  );
}
