import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    marginBottom: 1,
    display: "flex",
    border: "1px solid #e0e0e0"
  },
  itemHighlight: {
    fontSize: 22,
    fontWeight: 600
  },
  label: {
    width: 125,
    background: "#fafbfc",
    padding: theme.spacing(2, 3),
    fontWeight: 600,
    textAlign: "left",
    borderRight: "1px solid #e0e0e0"
  },
  value: {
    flex: 1,
    padding: theme.spacing(2, 3),
    textAlign: "right"
  },
  red: {
    color: "red"
  }
}));

const MiniSummary = ({ items }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {items.map((item, key) => {
        const { highlight, isNegative, label, value } = item;

        return (
          <div
            className={classnames(
              classes.item,
              highlight && classes.itemHighlight
            )}
            key={key}
          >
            <div className={classes.label}>{label}</div>
            <div
              className={classnames(classes.value, isNegative && classes.red)}
            >
              {value}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MiniSummary;
