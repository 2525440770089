export const COLLECTION_NAME = "clients";

export const getCollectionName = (uid) => `users/${uid}/${COLLECTION_NAME}`;

export const DEFAULT_FORM_DATA = {
  name: "",
  email: "",
  phone: "",
  website: "",
  abn: "",
  acn: "",
  vatNumber: "",
  notes: "",
};

export const CONTACT_DEFAULT_FORM_DATA = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  notes: ""
};

export const SCHEMA = [
  {
    type: "text",
    name: "name",
    label: "Name",
    required: true,
    disabled: false,
  },
  {
    type: "email",
    name: "email",
    label: "Email",
    required: true,
    disabled: false,
  },
  {
    type: "text",
    name: "phone",
    label: "Phone",
    helperText: "Don't forget to put the country number. Example: +61 for Australian number",
    required: true,
    disabled: false,
  },
  {
    type: "text",
    name: "website",
    label: "Website",
    required: false,
    disabled: false,
  },
  {
    type: "text",
    name: "abn",
    label: "ABN",
    helperText: "ABN is required for business transaction",
    required: false,
    disabled: false,
  },
  {
    type: "text",
    name: "acn",
    label: "ACN",
    required: false,
    disabled: false,
  },
  {
    type: "textarea",
    name: "notes",
    label: "Notes",
    required: true,
    disabled: false,
  },
];