import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginBottom: theme.spacing(2),
  },
  errorCodeContainer: {
    position: "relative",
    paddingRight: theme.spacing(4),
    marginRight: theme.spacing(4),
    alignItems: "center",
    textAlign: "right",

    "&::after": {
      content: "''",
      position: 'absolute',
      right: 0,
      top: "calc(50% - 18px)",
      background: "#ccc",
      borderRadius: 30,
      width: 5,
      height: 36,
    }
  },
  errorBodyContainer: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    maxWidth: 450,
  },
  errorBodyWrapper: {
    "& > *": {
      marginBottom: theme.spacing(2),

      "&:last-child": {
        marginBottom: 0,
      }
    },
  },
  heroHeading: {
    display: "block",
    fontSize: 100,
    fontWeight: 900,
    lineHeight: 1,
  },
  heroSubheading: {
    display: "block",
    fontSize: 22,
    fontWeight: 900,
    textTransform: 'uppercase',
  },
}));

const MiniOnboarding = ({ errorCode, errorHeading, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.errorCodeContainer}>
        {errorCode && <div className={classes.heroHeading}>{errorCode}</div>}
        {errorHeading && <div className={classes.heroSubheading}>{errorHeading}</div>}
      </div>
      {children && (
        <div className={classes.errorBodyContainer}>
          <div className={classes.errorBodyWrapper}>
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

export default MiniOnboarding;
