import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import { PageLayoutDefault } from "../../components/PageLayout";
import PageTitle from "../../components/PageTitle";
import InvoiceForm from "../../features/InvoiceForm";
import { getCollectionName, DEFAULT_FORM_DATA } from "../../schema/invoice";

const REDIRECT_ON_SUCCESS = "/invoices";

const InvoiceFormNewPage = (props) => {
  const routerParams = props.match.params;
  const { id = "" } = routerParams;

  // Setup
  // -----------
  // Auth
  const { uid } = useSelector((state) => state.firebase.auth);

  // Router
  let history = useHistory();

  // Firestore
  const firestore = useFirestore();
  const COLLECTION_NAME = getCollectionName(uid);

  // Forms
  const [formData, setFormData] = useState(DEFAULT_FORM_DATA);

  // Functions
  // -----------
  const onSubmit = (data) => {
    let finalData = {
      ...formData,
      uid: uid,
      timestamp: firestore.FieldValue.serverTimestamp(),
      created: firestore.FieldValue.serverTimestamp()
    };

    let apiCall;

    if (id) {
      apiCall = firestore.collection(COLLECTION_NAME).doc(id).update(finalData);
    } else {
      apiCall = firestore.collection(COLLECTION_NAME).add(finalData);
    }

    apiCall.then(() => {
      // Redirect on success
      history.push(REDIRECT_ON_SUCCESS);
    });
  };

  // Hooks
  // -----------

  return (
    <PageLayoutDefault>
      <PageTitle parentTitle="Invoices" parentLink={REDIRECT_ON_SUCCESS}>
        {id ? "Edit invoice" : "Add new invoice"}
      </PageTitle>
      <InvoiceForm
        formData={formData}
        setFormData={setFormData}
        onSubmit={onSubmit}
      />
    </PageLayoutDefault>
  );
};

export default InvoiceFormNewPage;
