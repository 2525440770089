import React from "react";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 36,
    fontWeight: "500",
    marginBottom: theme.spacing(3),
    "&:last-child": {
      marginBottom: 0
    }
  },
  subtitle: {
    display: "block",
    fontSize: 14,
    fontWeight: 800,
    textTransform: "uppercase",
    textDecoration: "none",
    color: theme.palette.primary.main
  }
}));

const PageTitle = (props) => {
  const { children, parentTitle, parentLink } = props;
  const classes = useStyles();

  return (
    <>
      {parentTitle && (
        <Link to={parentLink} className={classes.subtitle}>
          {parentTitle}
        </Link>
      )}
      <Typography className={classes.title} component="h1">
        {children}
      </Typography>
    </>
  );
};

export default PageTitle;
