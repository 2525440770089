import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  useFirestore,
  useFirestoreConnect,
  isLoaded
} from "react-redux-firebase";
import { PageLayoutDefault } from "../../components/PageLayout";
import PageTitle from "../../components/PageTitle";
import FullLoading from "../../components/FullLoading";
import ClientForm from "../../features/ClientForm";
import { getCollectionName, DEFAULT_FORM_DATA } from "../../schema/client";

const REDIRECT_ON_SUCCESS = "/clients";

const ClientFormEditPage = (props) => {
  const routerParams = props.match.params;
  const { id = "" } = routerParams;

  // Setup
  // -----------
  // Auth
  const { uid } = useSelector((state) => state.firebase.auth);

  // Router
  let history = useHistory();

  // Firestore
  const COLLECTION_NAME = getCollectionName(uid);
  const firestore = useFirestore();
  useFirestoreConnect([{ collection: COLLECTION_NAME, doc: id }]);
  const fsdata = useSelector(
    ({ firestore: { data } }) =>
      data[COLLECTION_NAME] && data[COLLECTION_NAME][id]
  );

  // Forms
  const [formData, setFormData] = useState(DEFAULT_FORM_DATA);

  // Functions
  // -----------
  const initiateData = () => {
    if (id && fsdata !== undefined) {
      const rawData = fsdata || {};

      let modifiedData = {
        ...DEFAULT_FORM_DATA,
        ...rawData
      };

      if (modifiedData.billedOn !== undefined && modifiedData.billedOn) {
        modifiedData.billedOn = modifiedData.billedOn.toDate();
      }
      if (modifiedData.paidOn !== undefined && modifiedData.paidOn) {
        modifiedData.paidOn = modifiedData.paidOn.toDate();
      }
      if (modifiedData.voidedOn !== undefined && modifiedData.voidedOn) {
        modifiedData.voidedOn = modifiedData.voidedOn.toDate();
      }

      setFormData(modifiedData);
    }
  };

  const onSubmit = (data) => {
    let finalData = {
      ...formData,
      timestamp: firestore.FieldValue.serverTimestamp()
    };

    let apiCall;

    if (id) {
      apiCall = firestore.collection(COLLECTION_NAME).doc(id).update(finalData);
    } else {
      apiCall = firestore.collection(COLLECTION_NAME).add(finalData);
    }

    apiCall.then(() => {
      // Redirect on success
      history.push(REDIRECT_ON_SUCCESS);
    });
  };

  // Hooks
  // -----------
  useEffect(initiateData, [JSON.stringify(fsdata)]);

  if (id && !isLoaded(fsdata)) {
    return <FullLoading />;
  }

  return (
    <PageLayoutDefault>
      <PageTitle parentTitle="Clients" parentLink={REDIRECT_ON_SUCCESS}>
        {id ? "Edit client" : "Add new client"}
      </PageTitle>
      <ClientForm
        formData={formData}
        setFormData={setFormData}
        onSubmit={onSubmit}
      />
    </PageLayoutDefault>
  );
};

export default ClientFormEditPage;
