import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  useFirestore,
  useFirestoreConnect,
  isLoaded
} from "react-redux-firebase";
import { PageLayoutDefault } from "../../components/PageLayout";
import PageTitle from "../../components/PageTitle";
import FullLoading from "../../components/FullLoading";
import StickyHeadTable from "../../components/StickyHeadTable";
import Paper from "@material-ui/core/Paper";
import FloatingActionButtonZoom from "../../components/FloatingActionButtonZoom";
import AddIcon from "@material-ui/icons/Add";
import { getCollectionName } from "../../schema/client";

const columns = [
  { id: "name", label: "Name", minWidth: 100 },
  { id: "email", label: "Email", minWidth: 100 },
  { id: "notes", label: "Notes", minWidth: 200 }
];

const ClientListPage = (props) => {
  // Setup
  // -----------
  // Firestore
  const { uid } = useSelector((state) => state.firebase.auth);
  const COLLECTION_NAME = getCollectionName(uid);
  const firestore = useFirestore();
  useFirestoreConnect([{ collection: COLLECTION_NAME }]);
  const list = useSelector((state) => state.firestore.ordered[COLLECTION_NAME]);

  // Functions
  // -----------
  const handleDelete = (record) => {
    if (record) {
      firestore
        .collection(COLLECTION_NAME)
        .doc(record.id)
        .delete()
        .then(() => {
          firestore.get(COLLECTION_NAME);
        });
    }
  };

  if (!isLoaded(list)) {
    return <FullLoading />;
  }

  return (
    <PageLayoutDefault>
      <PageTitle>Clients</PageTitle>
      <Paper elevation={1}>
        <StickyHeadTable
          columns={columns}
          rows={list}
          onDelete={handleDelete}
          deletable={true}
          editable={true}
          editLink={(item) => `/clients/edit/${item.id}`}
        />
      </Paper>
      <FloatingActionButtonZoom
        label="Add"
        icon={<AddIcon />}
        component={Link}
        to="clients/new"
        color="primary"
      />
    </PageLayoutDefault>
  );
};

export default ClientListPage;
