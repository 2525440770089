import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import SidebarMenu from "../SidebarMenu";
import Logo from "../Logo";
import { makeStyles } from "@material-ui/core/styles";
import { getMenuItems } from "../../schema/menu";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "300px",
    background: "#F5F6F8",
    borderRight: "1px solid #ddd"
  },
  logoContainer: {
    padding: theme.spacing(4, 4)
  },
  menuContainer: {}
}));

const AppSidebar = (props) => {
  const classes = useStyles();
  let history = useHistory();
  const auth = useSelector((state) => state.firebase.auth);

  return (
    <div className={classes.root}>
      <div className={classes.logoContainer}>
        <Logo />
      </div>
      <div className={classes.menuContainer}>
        <SidebarMenu items={getMenuItems({ history, auth })} />
      </div>
    </div>
  );
};

export default AppSidebar;
