/**
 * Helper function to build field from schema
 *
 * @param schema
 * @param formData
 * @param callbacks
 */
export const buildFieldsFromSchema = (schema = [], formData = {}, callbacks = {}) => {
  const {
    handleChange,
    register,
    errors,
  } = callbacks;

  let fields = {};

  schema.forEach(field => {
    const { name, required, validation = {}, helperText = false } = field;

    if(required === true) {
      validation.required = true;
    }

    const error = errors && (errors[name] !== undefined) ? errors[name] : false;

    const newField = {
      ...field,
      value: formData && (formData[name] !== undefined) ? formData[name] : "",
      error: error !== false,
      helperText: error && error.message ? error.message : helperText,
      inputRef: register(validation),
      onChange: e => handleChange(name, e.target.value),
    };

    fields[name] = newField;
  });

  console.log(fields);

  return fields;
};