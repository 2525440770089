import React from "react";
import AppBody from "../../App/AppBody";
import AppWrapper from "../../App/AppWrapper";
import AppSidebar from "../../App/AppSidebar";
import "./PageLayoutDefault.scss";

export default function PageLayoutDefault({ showSidebar = true, children }) {
  return (
    <AppWrapper>
      {showSidebar && <AppSidebar />}
      <AppBody>
        {children}
      </AppBody>
    </AppWrapper>
  );
}
