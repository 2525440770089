import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Link } from "react-router-dom";
import DeleteConfirmationDialog from "../Dialog/DeleteConfirmationDialog";

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 440
  }
});

const StickyHeadTable = (props) => {
  const {
    rows = [],
    columns = [],
    onDelete = null,
    deletable = false,
    editable = false,
    editLink = null,
    viewable = false,
    viewLink = null
  } = props;

  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const openDeleteDialog = (row) => {
    setShowDeleteDialog(true);
    setSelectedRow(row);
  };

  const closeDeleteDialog = () => {
    setShowDeleteDialog(false);
    setSelectedRow({});
  };

  const confirmDelete = () => {
    if (onDelete) {
      onDelete(selectedRow);
    }

    closeDeleteDialog();
  };

  const getEditLink = (item) => {
    return editable && editLink ? editLink(item) : null;
  };

  const getViewLink = (item) => {
    return viewable && viewLink ? viewLink(item) : null;
  };

  let tableActions = 0;
  if (viewable) {
    tableActions++;
  }
  if (editable) {
    tableActions++;
  }
  if (deletable) {
    tableActions++;
  }

  return (
    <div className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
              {tableActions > 0 && (
                <TableCell
                  key={"header-editable"}
                  style={{ width: tableActions * 50 }}
                  align={"right"}
                >
                  Actions
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length === 0 && (
              <TableRow>
                <TableCell colSpan={columns.length + tableActions} align="center">
                  <em>No items</em>
                </TableCell>
              </TableRow>
            )}
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                const individualViewLink = getViewLink(row);
                const individualEditLink = getEditLink(row);

                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                    {tableActions > 0 && (
                      <TableCell key={`${row.id}-actions`} align={"right"}>
                        {viewable && individualViewLink && (
                          <Link to={individualViewLink}>
                            <IconButton aria-label="View">
                              <VisibilityIcon />
                            </IconButton>
                          </Link>
                        )}
                        {editable && individualEditLink && (
                          <Link to={individualEditLink}>
                            <IconButton aria-label="Edit">
                              <EditIcon />
                            </IconButton>
                          </Link>
                        )}
                        {deletable && (
                          <IconButton
                            aria-label="Delete"
                            onClick={(e) => openDeleteDialog(row)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100, 250]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />

      <DeleteConfirmationDialog
        open={showDeleteDialog}
        handleConfirm={confirmDelete}
        handleClose={closeDeleteDialog}
      />
    </div>
  );
};

export default StickyHeadTable;
