import React from "react";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import ClientAutocompleteField from "../ClientAutocompleteField";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import moment from "moment";

/*
const sampleFields = [
  {
    type: "text",
    name: "name",
    label: "Name",
    required: true,
    value: "a",
    disabled: false
  }
];
*/

const DynamicFormFields = ({ fields }) => {
  return (
    <>
      {fields.map((field, key) => {
        const {
          visible = true,
          inputRef,
          type = "",
          error,
          name,
          label,
          required = false,
          value,
          checked,
          disabled = false,
          rows = 4,
          placeholder,
          helperText,
          onChange,
          currencyText = "$",
          items = []
        } = field;

        if (!visible) return "";

        const defaultProps = {
          key: key,
          error: error,
          name: name,
          label: label,
          required: required,
          value: value,
          disabled: disabled,
          margin: "normal",
          onChange: onChange
        };

        switch (type) {
          case "text":
            return (
              <TextField
                {...defaultProps}
                inputRef={inputRef}
                placeholder={placeholder}
                helperText={helperText}
                variant="outlined"
                fullWidth
              />
            );

          case "textarea":
            return (
              <TextField
                {...defaultProps}
                inputRef={inputRef}
                placeholder={placeholder}
                helperText={helperText}
                variant="outlined"
                fullWidth
                multiline
                rows={rows}
              />
            );

          case "number":
            return (
              <TextField
                {...defaultProps}
                inputRef={inputRef}
                placeholder={placeholder}
                helperText={helperText}
                variant="outlined"
                fullWidth
                type="number"
              />
            );

          case "email":
            return (
              <TextField
                {...defaultProps}
                inputRef={inputRef}
                placeholder={placeholder}
                helperText={helperText}
                variant="outlined"
                fullWidth
                type="email"
              />
            );

          case "currency":
            return (
              <FormControl
                key={key}
                fullWidth
                variant="outlined"
                margin={defaultProps.margin}
              >
                <InputLabel htmlFor="outlined-adornment-amount">
                  {defaultProps.label}
                </InputLabel>
                <OutlinedInput
                  {...defaultProps}
                  inputRef={inputRef}
                  margin="none"
                  placeholder={placeholder}
                  startAdornment={
                    <InputAdornment position="start">
                      {currencyText}
                    </InputAdornment>
                  }
                  labelWidth={60}
                  type="number"
                />
                {helperText && (
                  <FormHelperText id="standard-weight-helper-text">
                    {helperText}
                  </FormHelperText>
                )}
              </FormControl>
            );

          case "percentage":
            return (
              <FormControl
                key={key}
                fullWidth
                variant="outlined"
                margin={defaultProps.margin}
              >
                <InputLabel htmlFor="outlined-adornment-amount">
                  {defaultProps.label}
                </InputLabel>
                <OutlinedInput
                  {...defaultProps}
                  inputRef={inputRef}
                  margin="none"
                  placeholder={placeholder}
                  endAdornment={
                    <InputAdornment position="end">%</InputAdornment>
                  }
                  labelWidth={60}
                  type="number"
                />
                {helperText && (
                  <FormHelperText id="standard-weight-helper-text">
                    {helperText}
                  </FormHelperText>
                )}
              </FormControl>
            );

          case "switch":
            return (
              <React.Fragment key={key}>
                <FormControlLabel
                  key={key}
                  inputRef={inputRef}
                  control={
                    <Switch
                      checked={checked}
                      onChange={onChange}
                      name={name}
                      color="primary"
                    />
                  }
                  label={label}
                  margin="normal"
                />

                {helperText && (
                  <FormHelperText id="standard-weight-helper-text">
                    {helperText}
                  </FormHelperText>
                )}
              </React.Fragment>
            );

          case "radio":
            return (
              <FormControl
                {...defaultProps}
                component="fieldset"
                margin="normal"
              >
                <FormLabel component="legend">{label}</FormLabel>
                <RadioGroup
                  aria-label={label}
                  name={name}
                  value={value}
                  onChange={onChange}
                >
                  {items.map((item, itemKey) => {
                    return (
                      <FormControlLabel
                        key={itemKey}
                        value={item.value}
                        control={<Radio />}
                        label={item.label}
                        disabled={item.disabled || false}
                        inputRef={inputRef}
                      />
                    );
                  })}
                </RadioGroup>
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
              </FormControl>
            );

          case "datepicker":
            return (
              <MuiPickersUtilsProvider
                key={key}
                libInstance={moment}
                utils={MomentUtils}
              >
                <DatePicker
                  {...defaultProps}
                  inputRef={inputRef}
                  inputVariant="outlined"
                  format="DD/MM/YYYY"
                  value={value}
                  fullWidth
                  helperText={helperText}
                  // KeyboardButtonProps={{
                  //   "aria-label": "change date"
                  // }}
                />
              </MuiPickersUtilsProvider>
            );

          case "autocomplete-client":
            return (
              <ClientAutocompleteField
                {...defaultProps}
                key={key}
              />
            );

          default:
            return null;
        }
      })}
    </>
  );
};

export default DynamicFormFields;
